<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<node
        *ngIf="node"
        [class.selected]="showDetails && this.menuOpened"
        [label]="node.label"
        awesome="fa-regular fa-folder-open"
        [(opened)]="node.open"
        (labelClick)="menuOpened = !menuOpened"
        [hasChildren]="true"
        [clickable]="showDetails">

        <dropdown-menu [(openInput)]="menuOpened" *ngIf="showDetails && menuOpened">
          <a [routerLink]="this.detailsPath + this.node.id" class="open-new-tab">
            <menu-item label="Details..." awesome="fa-regular fa-eye"></menu-item>
          </a>
        </dropdown-menu>

    <node
            *ngIf="node.open"
            class="subjects"
            label="Subjects"
            awesome="fas fa-user-injured"
            [(opened)]="node.subjectsOpen"
            [hasChildren]="hasDependency(this.node.subjects)">

        <ng-container *ngIf="node.subjectsOpen && node.subjects && node.subjects != $any('UNLOADED')">
            <subject-node
                    *ngFor="let subject of node.subjects"
                    [input]="subject"
                    [studyId]="node.id"
                    (selectedChange)="selectedChange.emit(node)"
                    [hasBox]="hasBox"
            >
            </subject-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="centers"
            label="Centers"
            awesome="fa-regular fa-hospital"
            [(opened)]="node.centersOpen"
            [hasChildren]="hasDependency(this.node.centers)">

        <ng-container *ngIf="node.centersOpen && node.centers && node.centers != $any('UNLOADED')">
            <center-node
                    *ngFor="let center of node.centers"
                    [input]="center">
            </center-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="studycards"
            label="Study Cards"
            awesome="fa-solid fa-shuffle"
            (firstOpen)="loadStudyCards()"
            [(opened)]="node.studycardsOpen"
            [hasChildren]="hasDependency(this.node.studyCards)"
            [dataLoading]="studyCardsLoading">

        <ng-container *ngIf="node.studycardsOpen && node.studyCards && node.studyCards != $any('UNLOADED')">
            <studycard-node
                    *ngFor="let studycard of node.studyCards;let i = index"
                    [input]="studycard"
                    (onCardDelete)="onCardDelete(i)"
            >
            </studycard-node>
        </ng-container>

    </node>

    <node
            *ngIf="node.open"
            class="members"
            label="Members"
            awesome="far fa-user"
            [(opened)]="node.membersOpen"
            [hasChildren]="hasDependency(this.node.members)">

        <ng-container *ngIf="node.membersOpen && node.members && node.members != $any('UNLOADED')">
            <member-node
                    *ngFor="let member of node.members"
                    [input]="member">
            </member-node>
        </ng-container>

    </node>
</node>

<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<form>
    <fieldset>
        <legend>Details</legend>
        <ol>
            <li>
                <label>Id</label>
                <span class="right-col">{{task.id}}</span>
            </li>
            <li>
                <label>Label</label>
                <span class="right-col">{{task.eventLabel}}</span>
            </li>
            <li>
                <label>Status</label>
                <span class="right-col">
                    <progress-bar [progress]="task?.progress" [warning]="task.status == 5 || task.status == 3"></progress-bar>
                    <button [disabled]="loading" *ngIf="task.eventType == 'downloadDataset.event' && (task?.status == -1 || task.status == 3) && browserCompatible" class="alt left-icon normal" (click)="retry()">
                        <i class="fas fa-download"></i>
                        Retry / Resume
                    </button>
                </span>
            </li>
            <li class="message-li">
                <label>Last message</label>
                <span class="right-col">
                    <div class="message">
                        <pre><code>{{task?.message}}</code></pre>
                    </div>
                </span>
            </li>
            <li *ngIf="task.route">
                <label>Link</label>
                <span class="right-col left-icon">
                    <a [routerLink]="task.route">
                        <i class="fa-solid fa-link"></i>
                        {{task.route}}
                    </a>
                </span>
            </li>
            <li>
                <label>Creation</label>
                <span class="right-col">{{task.creationDate | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
            </li>
            <li>
                <label>Last update</label>
                <span class="right-col">{{task.lastUpdate | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
            </li>
            <li class="message-li" *ngIf="task.report && task._eventType != 'checkQuality.event' && task._eventType != 'importDataset.event'">
                <label>Report</label>
                <span class="right-col">
                    <div class="message">
                        <pre><code>{{task?.report}}</code></pre>
                    </div>
                </span>
            </li>
        </ol>
        <ol *ngIf="this.report && (task._eventType == 'checkQuality.event' || task._eventType == 'importDataset.event')">
            <legend>Report</legend>
            <li>
                <shanoir-table #table class="report"
                    [getPage]="getPage.bind(this)"
                    [columnDefs]="reportColumns"
                    [customActionDefs]="reportActions"
                    [browserSearch]="false"
                    [collapseControls]="true"
                    (registerRefresh)="registerTableRefresh($event)"
                ></shanoir-table>
            </li>
        </ol>
    </fieldset>
</form>

<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="content-component">
    <form #formContainer *ngIf="form" [formGroup]="form" class="max-content" novalidate [class.disabled]="footerState.loading">
		<div class="layout">
			<span [ngSwitch]="mode">
				<ng-template [ngSwitchCase]="'view'"> 
					<h2 class="header command-zone"i18n="View manuf|Title@@manufDetailViewTitle">Details on manufacturer</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'edit'"> 
					<h2 class="header command-zone"i18n="Edit manuf|Title@@manufDetailEditTitle">Edit manufacturer</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'create'"> 
					<h2 class="header command-zone"i18n="Create manuf|Title@@manufDetailCreateTitle">Create manufacturer</h2>
				</ng-template>
			</span>
			<help-message [help]="'equipment'"></help-message>
			<fieldset>
				<ol>
					<li>
						<label i18n="Manuf detail|Name label@@manufDetailName">Manufacturer name</label> 
						<span class="right-col" [ngSwitch]="mode">
							<ng-template [ngSwitchCase]="'view'"> 
								{{manuf.name}}
							</ng-template>
							<ng-template ngSwitchDefault> 
								<input type="text" id="name" formControlName="name" [(ngModel)]="manuf.name" (change)="isNameUniqueError=false"/>
								<label *ngIf="hasError('name', ['required'])" class="form-validation-alert" i18n="Manuf detail|Name required error@@manufDetailNameRequiredError">Name is required!</label>
								<label *ngIf="hasError('name', ['minlength', 'maxlength'])" class="form-validation-alert" i18n="Manuf detail|Name length error@@manufDetailNameLengthError">Name length must be between 2 and 200!</label>
								<label *ngIf="hasError('name', ['unique'])" class="form-validation-alert" i18n="Manuf detail|Name unique error@@manufDetailNameUniqueError">Name should be unique!</label>
							</ng-template>
						</span>
					</li>
				</ol>
			</fieldset>
			<form-footer
				[state]="footerState"
				(save)="save()"
                (delete)="delete()"
				(edit)="goToEdit()"
				(cancel)="goToView()"
				(back)="goBack()"
			></form-footer>
        </div>
    </form>
</div>
<div class="cell">
    <form #window class="window" [formGroup]="form">
        <h2 class="header">Download Datasets</h2>
        <div *ngIf="loading">
            <i *ngIf="loading" class="fas fa-cog fa-spin"></i> Retrieving download informations...
        </div>
        <fieldset class="body" disabled="loading">
            <ol>
                <li *ngIf="hasDicom">
                    <label>Dataset files format</label>
                    <span class="right-col">
                        <select-box formControlName="format" [options]="formatOptions"></select-box>
                    </span>
                </li>
                <li *ngIf="hasDicom && form.get('format').value == 'nii'">
                <label>Nifti converter</label>
                <span class="right-col">
                    <select-box formControlName="converter" [options]="niftiConverters" [(ngModel)]="converter"></select-box>
                </span>
                </li>
                <li>
                    <label>Unzip datasets (slower)</label>
                    <span class="right-col">
                        <checkbox formControlName="unzip"></checkbox>
                        <span class="warning" *ngIf="form.get('unzip').value">
                            <i class="fas fa-exclamation-triangle"></i>
                            The unzipping will run on your computer
                        </span>
                    </span>
                </li>
                <li *ngIf="winOs">
                    <label>
                        Short paths
                        <tool-tip>Windows cannot unzip files to a path longer than 260 characters. If you encounter this problem while trying to download and/or unzip files, you may try this option (and / or choose a target directory with a shorter path).</tool-tip>
                    </label>
                    <span class="right-col">
                        <checkbox formControlName="shortPath"></checkbox>
                    </span>
                </li>
            </ol>
        </fieldset>
        <div class="footer">
            <button type="button" type="button" (click)="downloadNow()" class="alt left-icon" [disabled]="form.dirty && !form.valid">
                <i class="fas fa-download"></i>Download now
            </button>
            <button type="button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>

<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="content-component">
	<form *ngIf="form" class="max-content" [formGroup]="form" [class.disabled]="footerState && footerState.loading">
		<div class="layout">
			<span [ngSwitch]="mode">
				<ng-template [ngSwitchCase]="'view'">
					<h2 class="header command-zone"i18n="View dataset|Title@@datasetDetailViewTitle">Details on {{dataset.datasetProcessing ? 'processed ' : '' }}dataset</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'edit'">
					<h2 class="header command-zone"i18n="Edit dataset|Title@@datasetDetailEditTitle">Edit {{dataset.datasetProcessing ? 'processed ' : '' }}dataset</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'create'">
					<h2 class="header command-zone"i18n="Create dataset|Title@@datasetDetailCreateTitle">Create dataset</h2>
				</ng-template>
			</span>

			<common-dataset-details [mode]="mode" [dataset]="dataset" [parentFormGroup]="form"></common-dataset-details>
			<mr-dataset-details *ngIf="dataset.type == 'Mr'" [mode]="mode" [dataset]="dataset" [parentFormGroup]="form"></mr-dataset-details>
            <eeg-dataset-details *ngIf="dataset.type == 'Eeg'" [mode]="mode" [dataset]="dataset"></eeg-dataset-details>

			<!-- TODO : other types -->

			<fieldset *ngIf="mode=='view' && !isMRS && hasDownloadRight && dataset.type != 'Eeg' && dataset.type != 'BIDS' && dataset.type != 'Measurement'">
				<legend>Preview</legend>
                <button *ngIf="!papayaLoaded" (click)="loadDicomInMemory()">Load preview</button>
				<papaya *ngIf="papayaLoaded" [params]="papayaParams"></papaya>
			</fieldset>

			<form-footer
					[state]="footerState"
					(edit)="goToEdit()"
					(cancel)="goToView()"
                    (delete) = "delete()"
					(back)="goBack()"
					(save)="save()">
				<button  *ngIf="mode == 'view' && hasDownloadRight" class="right-icon dl-button" type="button" (click)="downloadAll()" [disabled]="downloadState.isActive()">Download<i class="fas fa-download"></i></button>
				<button *ngIf="mode=='view' && hasDownloadRight && this.dataset.type != 'Eeg' && this.dataset.type != 'BIDS' && !this.dataset.datasetProcessing" type="button" class="right-icon" (click)="seeDicomMetadata()">Show DICOM metadata<i class="fas fa-file-code"></i></button>
            </form-footer>
		</div>
	</form>
</div>

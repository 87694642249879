<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<node
        *ngIf="node"
        [class.selected]="this.menuOpened"
        [label]="node.label"
        [awesome]="awesome"
        [(opened)]="node.open"
        [hasChildren]="hasChildren()"
        (labelClick)="menuOpened = !menuOpened"
        [dataLoading]="loading"
        [title]="node.title + ' n°' + node.id">
  
    <ng-container *ngIf="node.open && node.studies && node.studies != $any('UNLOADED')">
        <reverse-study-node
                *ngFor="let studyNode of node.studies"
                [input]="studyNode"
                [subjectId]="node.id"
                (selectedChange)="selectedChange.emit()"
                [hasBox]="hasBox">
        </reverse-study-node>
    </ng-container>
</node>

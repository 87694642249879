<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div #formContainer class="content-component">
	<form *ngIf="form" [formGroup]="form" class="max-content" novalidate [class.disabled]="footerState.loading">
		<div class="layout">
			<span [ngSwitch]="mode">
				<ng-template [ngSwitchCase]="'view'">
					<h2 class="header command-zone">Details on quality card</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'edit'">
					<h2 class="header command-zone">Edit quality card</h2>
				</ng-template>
				<ng-template [ngSwitchCase]="'create'">
					<h2 class="header command-zone">Create quality card</h2>
				</ng-template>
			</span>
			<fieldset>
				<ol>
					<li>
						<label>Name</label>
						<span class="right-col" [ngSwitch]="mode">
							<ng-template [ngSwitchCase]="'view'">
								{{qualityCard.name}}
							</ng-template>
							<ng-template ngSwitchDefault>
								<input type="text" formControlName="name" [(ngModel)]="qualityCard.name" />
								<label *ngIf="hasError('name', ['required'])" [@slideDown]="hasError('name', ['required'])" class="form-validation-alert">Name is required!</label>
								<label *ngIf="hasError('name', ['minlength', 'maxlength'])" [@slideDown]="hasError('name', ['minlength'])" class="form-validation-alert">Too short!</label>
								<label *ngIf="hasError('name', ['unique'])" [@slideDown]="hasError('name', ['unique'])" class="form-validation-alert">Name should be unique!</label>
							</ng-template>
						</span>
					</li>
					<li>
						<label>Study</label>
						<span class="right-col" [ngSwitch]="mode">
							<ng-template [ngSwitchCase]="'view'">
							    <a [routerLink]="['/study/details/', qualityCard.study?.id]" (click)="breadcrumbsService.markMilestone()">
								    {{qualityCard.study.name}}
								</a>
							</ng-template>
							<ng-template ngSwitchDefault>
								<select-box [readOnly]="forceStudyId" formControlName="study" [(ngModel)]="qualityCard.study" (userChange)="onStudyChange()" [optionArr]="studies" [disabled]="lockStudy">
								</select-box>
							</ng-template>
						</span>
					</li>
					<li>
						<label>Auto check at import</label>
						<span class="right-col" [ngSwitch]="mode">
							<ng-container *ngIf="mode == 'view'">
								<span *ngIf="qualityCard.toCheckAtImport" class="bool-true"><i class="fas fa-check"></i></span>
								<span *ngIf="!qualityCard.toCheckAtImport" class="bool-false"><i class="fas fa-times"></i></span>
							</ng-container>
							<ng-container *ngIf="mode != 'view'">
								<checkbox [(ngModel)]="qualityCard.toCheckAtImport" formControlName="toCheckAtImport"></checkbox>
							</ng-container>
						</span>
					</li>
				</ol>
			</fieldset>
			<fieldset class="rules">
				<legend>
					Rules
				</legend>
				<div class="info left-icon">
					This rule set will be applied to each given examination
				</div>
				<study-card-rules class="rules" [formGroup]="form"
					cardType="qualitycard"
					formControlName="rules"
					[(ngModel)]="qualityCard.rules" 
					[mode]="selectMode ? 'select' : mode" 
					[allCoils]="allCoils"
					[studyId]="qualityCard.study?.id"
					[showErrors]="showRulesErrors"
					(selectedRulesChange)="selectedRules = $event"
					[addSubForm]="addConditionForm.bind(this)">
				</study-card-rules>
			</fieldset>
			<fieldset *ngIf="progress > 0 && progress < 1" class="progress">
				<progress-bar [progress]="progress"></progress-bar>
				Computing quality checks...
			</fieldset>
			<fieldset class="report" *ngIf="report">
				<legend>
					Application Report 
					<span *ngIf="reportIsTest">(test)</span>
				</legend>
				<shanoir-table #table class="report"
                    [getPage]="getPage.bind(this)"
                    [columnDefs]="reportColumns"
                    [browserSearch]="false"
                    [collapseControls]="true"
                ></shanoir-table>
			</fieldset>
			<form-footer
				[state]="footerState"
				(save)="save()"
                (delete) = "delete()"
				(edit)="goToEdit()"
				(cancel)="goToView()"
				(back)="goBack()">
				<button *ngIf="mode != 'view'"
						type="button" 
						class="Button right-icon show-errors" 
						(click)="onShowErrors()"
						[disabled]="footerState.valid || showRulesErrors || qualityCard.rules?.length == 0">
					Show all errors<i class="fas fa-bug"></i>
				</button>
				<button 
						*ngIf="mode == 'view' && isStudyAdmin"
						type="button"
						class="right-icon apply test" 
						(click)="test()" 
						[disabled]="applying || testing">
					Test
                    <i *ngIf="!testing" class="fa-solid fa-play"></i>
                    <i *ngIf="testing" class="fa fa-cog fa-spin"></i> 
                </button>
				<button 
						*ngIf="mode == 'view' && isStudyAdmin"
						type="button"
						class="alt right-icon apply" 
						(click)="apply()" 
						[disabled]="applying || testing">
					Apply now
					<i *ngIf="!applying" class="fa-solid fa-play"></i>
                    <i *ngIf="applying" class="fa fa-cog fa-spin"></i> 
                </button>
				<button *ngIf="report" type="button" class="apply right-icon" (click)="downloadReport()">
					Download error report
					<i class="fas fa-floppy-disk"></i>
				</button>
			</form-footer>
		</div>
	</form>
</div>

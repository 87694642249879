<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="PathologyFormComponent content-component">
    <form *ngIf="form"  [formGroup]="form" class="max-content" novalidate>
		<table class="layout">
			<tr><td colspan="2">
				<span class="right-col" [ngSwitch]="mode">
					<ng-template [ngSwitchCase]="'view'"> 
						<div class="header command-zone" i18n="View pathology|Title">View pathology</div>
					</ng-template>
					<ng-template [ngSwitchCase]="'edit'"> 
						<div class="header command-zone" i18n="Edit pathology|Title">Edit pathology</div>
					</ng-template>
					<ng-template [ngSwitchCase]="'create'"> 
						<div class="header command-zone" i18n="Create pathology|Title">Add pathology</div>
					</ng-template>
				</span>
			</td></tr>
			<tr>
				<td>
					<fieldset>
					<ol>
						<!-- <label *ngIf="isPathologyUnique==false" class="form-validation-alert" i18n="Edit pathology|PathologyUniqueError label">This pathology already exists!</label> -->
						<li class="required">
							<label i18n="Edit pathology|Name label">Name</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{pathology?.name}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<input type="text" id="name" formControlName="name" [(ngModel)]="pathology.name"/>
									<label *ngIf="hasError('name', ['required'])" [@slideDown]="hasError('name', ['required'])" class="form-validation-alert" i18n="Edit pathology|Name required error@@pathologyDetailNameRequiredError">Name is required!</label>
								</ng-template>
							</span>
						</li>
					</ol>
					</fieldset>
				</td>				
			</tr>
			
		</table>
		<form-footer
				[state]="footerState"
				(save)="save()"
                (delete) = "delete()"
				(edit)="goToEdit()"
				(cancel)="goToView()"
				(back)="goBack()">
		</form-footer>
    </form>
</div>
<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="content-component">
  <form *ngIf="form" [formGroup]="form" class="max-content" novalidate [class.disabled]="denyLoading || acceptLoading || footerState.loading">
    <h2 class="header command-zone"*ngIf="mode == 'create'" i18n="Create user|Title@@createUserTitle">Create user</h2>
    <h2 class="header command-zone"*ngIf="mode != 'create'" i18n="Edit user|Title@@editUserTitle">Edit user</h2>
    <fieldset>
      <ol>
        <li>
          <label i18n="Edit user|First name label@@editUserFirstName">First Name</label>
          <span class="right-col" [ngSwitch]="mode">
						<ng-template [ngSwitchCase]="'view'">
							{{user.firstName}}
						</ng-template>
						<ng-template ngSwitchDefault>
							<input type="text" id="firstName" formControlName="firstName" [(ngModel)]="user.firstName"/>
						</ng-template>
						<label *ngIf="hasError('firstName', ['required'])" class="form-validation-alert" i18n="Edit user|First name required error@@editUserFirstNameRequiredError">First Name is required!</label>
						<label *ngIf="hasError('firstName', ['length'])" class="form-validation-alert" i18n="Edit user|First name length error@@editUserFirstNameLengthError">First Name length must be between 2 and 50!</label>
					</span>
        </li>
        <li>
          <label i18n="Edit user|Last name label@@editUserLastName">Last Name</label>
          <span class="right-col" [ngSwitch]="mode">
						<ng-template [ngSwitchCase]="'view'">
							{{user.lastName}}
						</ng-template>
						<ng-template ngSwitchDefault>
							<input type="text" id="lastName" formControlName="lastName" [(ngModel)]="user.lastName"/>
						</ng-template>
						<label *ngIf="hasError('lastName', ['required'])" class="form-validation-alert" i18n="Edit user|Last name required error@@editUserLastNameRequiredError">Last Name is required!</label>
						<label *ngIf="hasError('lastName', ['length'])" class="form-validation-alert" i18n="Edit user|Last name length error@@editUserLastNameLengthError">Last Name length must be between 2 and 50!</label>
					</span>
        </li>
        <li *ngIf="mode != 'create'">
          <label i18n="Edit user|Username label@@editUserUsername">Username</label>
          <span class="right-col">
						{{user.username}}
					</span>
        </li>
        <li>
          <label i18n="Edit user|Email label@@editUserEmail">Email</label>
          <span class="right-col" [ngSwitch]="mode">
						<ng-template [ngSwitchCase]="'view'">
							{{user.email}}
						</ng-template>
						<ng-template ngSwitchDefault>
							<input type="email" id="email" formControlName="email" [(ngModel)]="user.email"/>
						</ng-template>
						<label *ngIf="hasError('email', ['required'])" class="form-validation-alert" i18n="Edit user|Email required error@@editUserEmailRequiredError">Email is required!</label>
						<label *ngIf="hasError('email', ['pattern'])" class="form-validation-alert" i18n="Edit user|Email pattern error@@editUserEmailPatternError">Email should be valid!</label>
						<label *ngIf="hasError('email', ['unique'])" class="form-validation-alert" i18n="Edit user|Email unique error@@editUserEmailUniqueError">Email should be unique!</label>
					</span>
        </li>
      </ol>
    </fieldset>
    <fieldset>
      <ol>
        <li>
          <label i18n="Edit user|Expiration date label@@editUserExpirationDate">Expiration Date</label>
          <span class="right-col">
						<ng-container *ngIf="mode == 'view' || !isUserAdmin()">
							{{user.expirationDate | date: 'dd/MM/yyyy'}}
						</ng-container>
						<ng-container *ngIf="mode != 'view' && isUserAdmin()">
							<datepicker formControlName="expirationDate" [(ngModel)]="user.expirationDate"></datepicker>
							<label *ngIf="hasError('expirationDate', ['format'])"  class="form-validation-alert" i18n="Edit user|Date valid error@@editUserDateValidError">Date should be valid! Date format: dd/mm/yyyy</label>
							<label *ngIf="hasError('expirationDate', ['future'])"  class="form-validation-alert" i18n="Edit user|Date valid error@@editUserDateFutureError">This date should be in the future!</label>
						</ng-container>
					</span>
        </li>
        <li class="required">
          <label i18n="Edit user|Role label@@editUserRole">Role of the user</label>
          <span class="right-col">
						<ng-container *ngIf="mode == 'view' || !isUserAdmin()">
							{{user.role.displayName}}
						</ng-container>
						<ng-container *ngIf="mode != 'view' && isUserAdmin()">
							<select id="role" formControlName="role" [(ngModel)]="user.role">
								<option *ngFor="let role of roles" [ngValue]="role">{{role.displayName}}</option>
							</select>
							<label *ngIf="hasError('role', ['required'])" class="form-validation-alert" i18n="Edit user|Role required error@@editUserRoleRequiredError">Role is required!</label>
						</ng-container>
					</span>
        </li>
        <li>
          <label i18n="Edit user|CanAccess to Dicom association label@@editUserCanAccessToDicomAssociation">Can import from PACS</label>
          <span class="right-col" [ngSwitch]="mode">
						<ng-container *ngIf="mode == 'view' || !isUserAdmin()">
							<span *ngIf="user.canAccessToDicomAssociation" class="bool-true"><i class="fas fa-check"></i></span>
							<span *ngIf="!user.canAccessToDicomAssociation" class="bool-false"><i class="fas fa-times"></i></span>
						</ng-container>
						<ng-container *ngIf="mode != 'view' && isUserAdmin()">
							<checkbox [(ngModel)]="user.canAccessToDicomAssociation" formControlName="canAccessToDicomAssociation"></checkbox>
						</ng-container>
					</span>
        </li>
      </ol>
    </fieldset>
    <fieldset *ngIf="isUserAdmin()">
      <legend>Joined studies</legend>
      <ol>
        <li *ngFor="let study of studies; let index = index" class="userStudies">
          <span class="studyName">{{study.name + ' (' + study.id + ')'}}</span>
          <span *ngIf="mode == 'edit'">
            <span class="right-col" [ngSwitch]="mode">
                <button type="button" class="deleteButton" (click)="removeStudyFromUser(study, index)">
                  <i class="fas fa-trash"></i>
                </button>
            </span>
          </span>
        </li>
      </ol>

    </fieldset>

    <account-request-info *ngIf="user.accountRequestDemand" [ngModel]="user.accountRequestInfo" formControlName="accountRequestInfo"></account-request-info>

    <fieldset *ngIf="user.extensionRequestDemand">
      <ol>
        <li>
          <label i18n="Extension comment |Extension comment label@@editUserExtensionComment">Extension comment</label>
          <span class="right-col">
						<textarea autosize formControlName="extensionMotivation" [(ngModel)]="user.extensionRequestInfo.extensionMotivation" readonly></textarea>
					</span>
        </li>
      </ol>
    </fieldset>

    <form-footer
      [state]="footerState"
      (save)="save()"
      (edit)="goToEdit()"
      (cancel)="goToView()"
      (back)="goBack()">
      <button type="submit" *ngIf="mode != 'create' && user.accountRequestDemand" (click)="deny()" i18n="Edit user|Deny account request button label@@editUserDenyAccountRequestButton"
              class="Button">
        Deny creation<span *ngIf="!denyLoading">
					<i class="fas fa-times"></i>
				</span><span *ngIf="denyLoading">
					<i class="fa fa-cog fa-spin"></i>
				</span>
      </button>
      <button type="submit" *ngIf="mode != 'create' && user.extensionRequestDemand" (click)="deny()" i18n="Edit user|Deny extension request button label@@editUserDenyExtensionRequestButton"
              class="Button">
        Deny extension<span *ngIf="!denyLoading">
					<i class="fas fa-times"></i>
				</span><span *ngIf="denyLoading">
					<i class="fa fa-cog fa-spin"></i>
				</span>
      </button>
      <button type="submit" *ngIf="mode != 'create' && (user.accountRequestDemand || user.extensionRequestDemand)" (click)="accept()"
              i18n="Edit user|Accept account request button label@@editUserAcceptAccountRequestButton" [disabled]="!form.valid">
        Accept<span *ngIf="!acceptLoading">
					<i class="fas fa-check"></i>
				</span><span *ngIf="acceptLoading">
					<i class="fa fa-cog fa-spin"></i>
				</span>
      </button>
      <button *ngIf="mode == 'view' && (user.id == keycloakService.getUserId())" type="submit" (click)="changePassword()" i18n="Edit user|Accept account request button label@@editUserAcceptAccountRequestButton">
        Change password
      </button>
    </form-footer>

  </form>
</div>

<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<div #formContainer class="RefFormComponent content-component">
    <form *ngIf="form"  [formGroup]="form" class="max-content" novalidate>
		<table class="layout">
			<tr><td colspan="2">
				<span class="right-col" [ngSwitch]="mode">
					<ng-template [ngSwitchCase]="'view'"> 
						<div class="header command-zone" i18n="View reference|Title">View Reference</div>
					</ng-template>
					<ng-template [ngSwitchCase]="'edit'"> 
						<div class="header command-zone" i18n="Edit reference|Title">Edit Reference</div>
					</ng-template>
					<ng-template [ngSwitchCase]="'create'"> 
						<div class="header command-zone" i18n="Create reference|Title">Add Reference</div>
					</ng-template>
				</span>
			</td></tr>
			<tr>
				<td>
					<fieldset>
					<ol>
						<li class="required">
							<label i18n="Edit reference|Category label">Category</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{reference?.category}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<span *ngIf="!isEditableCategory">{{reference?.category}}</span>
									<input [hidden]="!(isFreeCategory && isEditableCategory)" type="text" id="category" formControlName="category" [(ngModel)]="reference.category"/>
									<select [hidden]="!(!isFreeCategory && isEditableCategory)" id="category" formControlName="category" [(ngModel)]="reference.category" (change)="onChangeCategory()">
										<option *ngFor="let category of categories" [ngValue]="category">{{category}}</option>
									</select>
									<button *ngIf="keycloakService.isUserAdmin() && !isFreeCategory && isEditableCategory" i18n="Edit reference|Create new category" (click)="switchToCreate('category')">
										<i class="fas fa-plus-square"></i>
										new category
									</button>
									<label *ngIf="hasError('category', ['required'])" [@slideDown]="hasError('category', ['required'])" class="form-validation-alert" i18n="Edit refCategory|Category required error@@referenceDetailCategoryRequiredError">Category is required!</label>
								</ng-template>
							</span>
						</li>
						<li class="required">
							<label i18n="Edit reference|Type label">Type</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{reference?.reftype}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<span *ngIf="!isEditableRefType">{{reference?.reftype}}</span>
									<input *ngIf="isFreeRefType && isEditableRefType" type="text" id="reftype" formControlName="reftype" [(ngModel)]="reference.reftype"/>
									<select *ngIf="!isFreeRefType  && isEditableRefType" id="reftype" formControlName="reftype" [(ngModel)]="reference.reftype">
										<option *ngFor="let reftype of reftypes" [ngValue]="reftype">{{reftype}}</option>
									</select>
									<button *ngIf="keycloakService.isUserAdmin() && !isFreeRefType && isEditableRefType" i18n="Edit reference|Create new reftype" (click)="switchToCreate('reftype')">
										<i class="fas fa-plus-square"></i>
										new type
									</button>
									<label *ngIf="hasError('reftype', ['required'])"  class="form-validation-alert" i18n="Edit reftype|TypeRequiredError label">RefType is required!</label>
								</ng-template>
							</span>
						</li>
						<li class="required">
							<label i18n="Edit reference|Value label">Value</label>
							<span class="right-col" [ngSwitch]="mode">
								<ng-template [ngSwitchCase]="'view'"> 
										{{reference?.value}}
								</ng-template>
								<ng-template ngSwitchDefault>
									<input type="text" id="value" formControlName="value" [(ngModel)]="reference.value"/>
									<label *ngIf="hasError('value', ['required'])" class="form-validation-alert" i18n="Edit reference|ValueRequiredError label">Value is required!</label>
								</ng-template>
							</span>
							</li>
					</ol>
					</fieldset>
				</td>
				
			</tr>
			
			
		</table>
		<form-footer
				[state]="footerState"
				(save)="save()"
                (delete)="delete()"
				(edit)="goToEdit()"
				(back)="goBack()">
		</form-footer>
    </form>
</div>